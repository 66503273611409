import React, { useState } from 'react';
import { usePreferencesContext } from 'contexts/preferences';
import styles from './style.module.scss';
import { buildClassName } from 'utils/build-class-name';
import Button from 'components/control/button';
import EarthIcon from 'components/icon/earth-icon';
import { useIsMobile } from 'hooks/use-size-class';

export type SatelliteControlProps = {
  onViewToggle(): string | undefined;
  isHidden?: boolean;
  className?: string;
  extraRightMargin?: boolean;
};

enum MapTypeLabel {
  Satellite = 'Satellite',
  Street = 'Street',
}

const SatelliteControl = ({ onViewToggle, isHidden = false, className, extraRightMargin }: SatelliteControlProps) => {
  const [buttonLabel, setButtonLabel] = useState<MapTypeLabel>(MapTypeLabel.Satellite);
  const { isMapSearchOpen } = usePreferencesContext();
  const isMobile = useIsMobile();

  const toggleSatelliteView = () => {
    const currentLabel = onViewToggle();
    if (currentLabel === mapkit.Map.MapTypes.Satellite) {
      setButtonLabel(MapTypeLabel.Street);
    } else if (currentLabel === mapkit.Map.MapTypes.Standard) {
      setButtonLabel(MapTypeLabel.Satellite);
    }
  };

  return (
    <div className={buildClassName(styles.component, className, isHidden && styles['not-visible'], isMapSearchOpen && isMobile && styles['extra-top-margin'], extraRightMargin && styles['extra-right-margin'])}>
      <Button
        onClick={toggleSatelliteView}
        label={buttonLabel}
        theme={'secondary'}
        Icon={EarthIcon}
      />
    </div>
  );
};

export default SatelliteControl;
